<template>
  <!-- 主要內容 -->
  <section class="container pb-8 ">
    <div class="row">
      <div class="col-12 col-md-8 mx-auto">
        <h2 class="font-weight-bold mb-3 mb-md-6">會員資料修改</h2>
        <el-form
          style="width: 100%;"
          :model="MemberForm"
          label-position="top"
          :rules="MemberRules"
          ref="MemberForm"
          class="cs-form"
          data-style="2"
          @submit.prevent.native
        >
          <!-- 帳號 mb-2 mb-md-6 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label" for="memberAccount">
              會員帳號
            </label>
            <div class="col-12 col-md-9 mb-2">{{ MemberForm.memberAccount }}</div>
          </div>
          <!-- 姓名 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberName">
              會員姓名
            </label>
            <el-form-item prop="memberName" class="col-12 col-md-9 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="姓名"
                type="text"
                id="memberName"
                v-model="MemberForm.memberName"
              />
            </el-form-item>
          </div>
          <!-- 收件人住址 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberZipCode">
              收件人住址
            </label>

            <el-form-item prop="memberZipCode" class="col-12 col-md-2 mb-4">
              <el-input
                placeholder="郵遞區號"
                v-model="MemberForm.memberZipCode"
                :disabled="true"
              ></el-input>
            </el-form-item>

            <el-form-item prop="memberCounty" class="col-12 col-md-3 mb-4">
              <el-select
                :style="`display: block;`"
                v-model="MemberForm.memberCounty"
                @change="SetCityList(1)"
                placeholder="請選擇縣市"
              >
                <el-option
                  v-for="item in CountyOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="memberCity" class="col-12 col-md-3 mb-4">
              <el-select
                :style="`display: block;`"
                v-model="MemberForm.memberCity"
                @change="SetZipCode()"
                placeholder="請選擇地區"
              >
                <el-option
                  v-for="item in CityOption"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="memberAddress" class="col-12 col-md-9 offset-md-3 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="地址"
                type="text"
                id="memberAddress"
                v-model="MemberForm.memberAddress"
              />
            </el-form-item>
          </div>
          <!-- 送出按紐 -->
          <div class="form-group row mb-6 mb-md-8">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-hover-primary text-white btn-lg border-radius-l w-100"
                @click="ModifyMember"
              >
                <span class="small">儲存</span>
              </button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </section>
</template>

<script>
import { MemberById, ModifyMember } from '@/api/MemberApi';
import { GetCountyList, GetCityList } from '@/lib/zipcode';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      MemberForm: {
        //  model data
        memberId: 0,
        memberAccount: '',
        memberName: '',
        memberMail: '',
        memberPhone: '',
        memberZipCode: '',
        memberCounty: '',
        memberCity: '',
        memberAddress: '',
        inSchool: 0,
        schoolId: 0,
        schoolDeptId: 0,
        schoolYearId: 0,
      },
      MemberRules: {
        // 檢查輸入的值規則
        memberName: [{ required: true, message: '請輸入名稱', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FFF\u3400-\u4DBF\u{20000}-\u{2A6DF}\u{2A700}-\u{2B73F}\u{2B740}-\u{2B81F}\u{2B820}-\u{2CEAF}\u{2CEB0}-\u{2EBEF}]*$/u, message: '僅可輸入中文', trigger: 'blur' }],
        memberZipCode: [{ required: true, message: '請輸入郵遞區號', trigger: 'blur' }],
        memberCounty: [{ required: true, message: '請選擇縣市', trigger: 'change' }],
        memberCity: [{ required: true, message: '請選擇地區', trigger: 'change' }],
        memberAddress: [{ required: true, message: '請輸入地址', trigger: 'blur' }],
      },
    };
  },
  created() {
    // 一開始執行的地方
    this.GetMemberData();
  },
  methods: {
    SetCountyList() {
      this.CountyOption = GetCountyList();
    },
    SetCityList(clear) {
      this.CityOption = GetCityList(this.MemberForm.memberCounty);
      if (clear === 1) {
        this.MemberForm.memberCity = '';
        this.MemberForm.memberZipCode = '';
      } else {
        const data = this.CityOption.filter((item) => this.MemberForm.memberCity === item.label);
        if (!data[0]) {
          this.MemberForm.memberCity = '';
          this.MemberForm.memberZipCode = '';
        }
      }
    },
    SetZipCode() {
      const data = this.CityOption.filter((item) => this.MemberForm.memberCity === item.label);
      if (data[0]) {
        this.MemberForm.memberZipCode = data[0].value;
      } else {
        this.MemberForm.memberZipCode = '';
      }
    },
    GetMemberData() {
      MemberById().then((response) => {
        if (response.data.statusCode === 0) {
          this.MemberForm = response.data.data;
          this.SetCountyList();
          this.SetCityList(0);
        }
      });
    },
    ModifyMember() {
      // 儲存按鈕
      this.$refs.MemberForm.validate((valid) => {
        if (valid) {
          ModifyMember(this.MemberForm).then((response) => {
            if (response.data.statusCode === 0) {
              this.$message({
                showClose: true,
                type: 'success',
                message: '修改成功',
              });
            }
          });
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '請將資料填寫完整',
          });
        }
      });
    },
  },
};
</script>

<style></style>
